// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "G_fL";
export var multiVendorBusinessesSection = "G_fD";
export var multiVendorClinetsSection = "G_fQ";
export var multiVendorDevExpertiseSection = "G_fM";
export var multiVendorDiscoverWorksSection = "G_fK";
export var multiVendorFeaturesSection = "G_fC";
export var multiVendorIdeaToLifeSection = "G_fJ";
export var multiVendorIndustriesSection = "G_fF";
export var multiVendorOurAchievementsSection = "G_fP";
export var multiVendorProcessSection = "G_fG";
export var multiVendorServicesSection = "G_fB";
export var multiVendorSuccessStoriesSection = "G_fN";
export var multiVendorTechStackSection = "G_fH";